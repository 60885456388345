<template>
  <div id="generalChart">
    <div class="pages hidden" id="pages">
      <div class="page_title">
        <div>
          <i class="text_deco"></i><span class="text" id="text"></span>
        </div>
        <span class="time" id="time"></span>
      </div>

      <div :class="{ none: !isBarChartVisible }" ref="refBarChartHolder">
        <bar-chart-horizen ref="refHorizenBarChart" id="horizenBarChart" customClass="bar_chart" />
        <svg width="300" height="300" class="svg_gradient">
          <defs>
            <linearGradient id="generalBarNegative" x1="0%" y1="0%" x2="100%" y2="0">
              <stop offset="0%" stop-color="#11ba06" />
              <stop offset="100%" stop-color="#0b8603" />
            </linearGradient>
            <linearGradient id="generalBarPositive" x1="0%" y1="0%" x2="100%" y2="0">
              <stop offset="0%" stop-color="#BF0101" />
              <stop offset="100%" stop-color="#FB3232" />
            </linearGradient>
          </defs>
        </svg>
      </div>

      <div :class="{ none: !isDonutChartVisible }" ref="refDonutChartHolder">
        <chart-legend ref="refDonutLegend" id="donutLegend" class="common_legend" />
        <donut-chart ref="refDonutChart" id="refDonutChart" customClass="donut_chart" />
      </div>

      <div :class="{ none: !isLineChartVisible }" ref="refLineChartHolder">
        <chart-legend ref="refLineLegend" id="lineLegend" class="common_legend" />
        <line-chart ref="refLineChart" id="refLineChart" customClass="line_chart" />
      </div>

      <div :class="{ none: !isKLineChartVisible }" ref="refKLineChartHolder">
        <k-line-chart ref="refKLineChart" id="refKLineChart" customClass="kline_chart" />
      </div>

      <div :class="{ none: !isBarDoubleChartVisible }" ref="refBarDoubleChartHolder">
        <chart-legend ref="refBarDoubleLegend" id="barDoubleLegend" class="common_legend" />
        <bar-double-chart ref="refBarDoubleChart" id="refBarDoubleChart" customClass="bar_double_chart" />
      </div>

      <div :class="{ none: !isBarStackedChartVisible }" ref="refBarStackedChartHolder">
        <chart-legend ref="refBarStackedLegend" id="barStackedLegend" class="common_legend" />
        <bar-stacked ref="refBarStackedChart" id="refBarStackedChart" customClass="bar_stacked_chart" />
      </div>

      <div :class="{ none: !isRoseChartVisible }" ref="refRoseChartHolder">
        <chart-legend ref="refRoseChartLegend" id="RoseChartLegend" class="common_legend" />
        <rose-chart ref="refRoseChart" id="refRoseChart" customClass="rose_chart" />
      </div>

      <div :class="{ none: !isBarRangeChartVisible }" ref="refBarRangeChartHolder">
        <chart-legend ref="refBarRangeChartLegend" id="BarRangeChartLegend" class="common_legend" />
        <bar-range-chart ref="refBarRangeChart" id="refBarRangeChart" customClass="bar_range_chart" />
      </div>

      <div :class="{ none: !isTableChartVisible }" ref="refTableChartHolder">
        <table-chart ref="refTableChart" id="refTableChart" customClass="table_chart" />
      </div>

      <div :class="{ none: !isTreeMapVisible }" ref="refTreeMapHolder">
        <tree-map ref="refTreeMap" id="refTreeMap" customClass="tree_map" />
      </div>
    </div>
  </div>
</template>
<script>
//特别注意：使用jquery的时候为了避免重名带来的干扰，选择器开头必须是generalChart
import $ from "jquery";
import anime from "animejs/lib/anime.es.js";
import { anyElement } from "@/components/anyElement.js";
import { sleep, setRem } from "@/utils/utils.js";
import roseChart from "@/components/roseChart/index.vue";
import donutChart from "@/components/donutChart/index_v1.0.vue";
import barChartHorizen from "@/components/barChartHorizen/index_v1.0.vue";
import lineChart from "@/components/lineChart/index.vue";
import kLineChart from "@/components/kLineChart/index_v1.0.vue";
import chartLegend from "@/components/chartLegend/index.vue";
import barDoubleChart from "@/components/barDoubleChart/index.vue";
import barStacked from "@/components/barStacked/index.vue";
import barRangeChart from "@/components/barRangeChart/index.vue";
import tableChart from "@/components/tableChart/index.vue";
import treeMap from "@/components/treeMap/index.vue";

export default {
  data: function () {
    return {
      titleText: null, //动画句柄
      titleTime: null, // 更新时间动画句柄
      widthRatio: 0.76, //高度除以宽度的比值
      isBarChartVisible: false, //默认柱状图默认不可见
      isDonutChartVisible: false, //默认饼图默认不可见
      isLineChartVisible: false, //默认线图默认不可见
      isKLineChartVisible: false, //K线图默认不可见
      isBarDoubleChartVisible: false, //双柱图默认不可见
      isBarStackedChartVisible: false, //堆积图默认不可见
      isRoseChartVisible: false, //玫瑰图默认不可见
      isBarRangeChartVisible: false, //区间柱图
      isTableChartVisible: false, //区间柱图
      isTreeMapVisible: false, //区间柱图
      colorArr: ["#38aeff", "#ff7f0f", "#2ca02c", "#d62728", "#9467bd", "#8c564b", "#e377c2", "#bcbd22", "#16becf"],
    };
  },
  components: {
    barChartHorizen,
    lineChart,
    kLineChart,
    roseChart,
    donutChart,
    chartLegend,
    barDoubleChart,
    barStacked,
    barRangeChart,
    tableChart,
    treeMap,
  },
  methods: {
    preview(commandData) {
      setRem(1080);
      this.show(commandData)
    },
    async hide() {
      return new Promise(async (resolve, reject) => {
        anime({
          targets: document.querySelector("#generalChart .text_deco"),
          duration: 200,
          delay: 0,
          easing: "easeOutExpo",
          autoplay: true,
          opacity: [1, 0],
          rotate: [360, 300],
          skewX: [-20, -20],
        });
        if (this.titleText) {
          this.titleText.runOut();
        }
        if (this.titleTime) {
          this.titleTime.runOut();
        }
        await sleep(200);
        this.isBarChartVisible = false;
        this.isDonutChartVisible = false;
        this.isLineChartVisible = false;
        this.isKLineChartVisible = false;
        this.isBarDoubleChartVisible = false;
        this.isBarStackedChartVisible = false;
        this.isRoseChartVisible = false;
        this.isBarRangeChartVisible = false;
        this.isTableChartVisible = false;
        this.isTreeMapVisible = false;
        $("#generalChart #pages").addClass("hidden");
        resolve();
      });
    },
    async show(commandData) {
      $("#generalChart #pages").removeClass("hidden");
      $("#generalChart #text").html(commandData.title);
      $("#generalChart #time").html(commandData.timeCyc);
      this.$nextTick(async () => {
        anime({
          targets: document.querySelector("#generalChart .text_deco"),
          duration: 900,
          delay: 400,
          easing: "easeOutExpo",
          autoplay: true,
          opacity: [0, 1],
          sclae: [0.2, 1],
          rotate: [0, 360],
          skewX: [-20, -20],
        });
        this.titleText = anyElement({
          targets: document.querySelector("#generalChart .text"),
          isSplitTxt: true,
          duration: 600,
          delay: 30,
          easing: "easeOutExpo",
          inEffect: "leftInBig",
          outEffect: "leftOutBig",
        });
        this.titleTime = anyElement({
          targets: document.querySelector("#generalChart .time"),
          isSplitTxt: true,
          duration: 600,
          delay: 30,
          easing: "easeOutExpo",
          inEffect: "leftInBig",
          outEffect: "leftOutBig",
        });
        await sleep(300);
        this.titleText.runIn();
        this.titleTime.runIn();
        //显示不同的图表
        this.handleChart(commandData);
      });
    },
    handleChart(commandData) {
      if (commandData.type == "柱形图") {
        this.isBarChartVisible = true;
        this.$nextTick(() => {
          this.renderBarChart(commandData.data);
        });
      } else if (commandData.type == "饼图") {
        this.isDonutChartVisible = true;
        this.$nextTick(() => {
          this.renderDonutChart(commandData.data);
        });
      } else if (commandData.type == "折线图") {
        this.isLineChartVisible = true;
        this.$nextTick(() => {
          this.renderLineChart(commandData.data);
        });
      } else if (commandData.type == "K线图") {
        this.isKLineChartVisible = true;
        this.$nextTick(() => {
          this.renderKLineChart(commandData.data);
        });
      } else if (commandData.type == "堆积柱形图") {
        this.isBarStackedChartVisible = true;
        this.$nextTick(() => {
          this.renderBarStackedChart(commandData.data);
        });
      } else if (commandData.type == "多柱图") {
        this.isBarDoubleChartVisible = true;
        this.$nextTick(() => {
          this.renderBarDoubleChart(commandData.data);
        });
      } else if (commandData.type == "玫瑰图") {
        this.isRoseChartVisible = true;
        this.$nextTick(() => {
          this.renderRoseChart(commandData.data);
        });
      } else if (commandData.type == "区间柱图") {
        this.isBarRangeChartVisible = true;
        this.$nextTick(() => {
          this.renderBarRangeChart(commandData.data);
        });
      } else if (commandData.type == "表格") {
        this.isTableChartVisible = true;
        this.$nextTick(() => {
          this.renderTableChart(commandData.data);
        });
      } else if (commandData.type == "方块图") {
        this.isTreeMapVisible = true;
        this.$nextTick(() => {
          this.renderRectTreeChart(commandData.data);
        });
      }
    },
    renderBarChart(data) {
      let width = this.$refs.refBarChartHolder.clientWidth;
      this.$refs.refHorizenBarChart.BarChart(data.data, {
        x: (d) => d.value1,
        y: (d) => d.label,
        numberSuffix: data.suffix,
        width: width,
        height: width * this.widthRatio,
        marginLeft: "auto",
        marginRight: width * 0.22,
        duration: 900,
        delay: 120,
        yPadding: 0.4,
        ease: "easeExp",
        numberPosition: "outside",
        // labelPosition: "top",
      });
    },
    renderLineChart(data) {
      //展示legend
      this.$refs.refLineLegend.createLegend({
        legend: data.legend,
        legendType: "line",
        colors: this.colorArr,
      });
      let width = this.$refs.refLineChartHolder.clientWidth;
      this.$refs.refLineChart.LineChart(data.data, {
        label: (d) => d.label,
        width: width,
        height: width * this.widthRatio * 0.9,
        marginTop: 40,
        marginBottom: 50,
        marginLeft: width * 0.1,
        marginRight: width * 0.15,
        duration: 2200,
        delay: 0,
        ease: "easeCubicInOut",
        curveName: "curveLinear",
        isFromZero: false,
        xTickNumber: 3,
        yTickNumber: 6,
        numberSuffix: data.suffix ? data.suffix : "",
        demicalDigit: 2,
      });
    },
    renderDonutChart(data) {
      //展示legend
      this.$refs.refDonutLegend.createLegend({
        legend: data.legend,
        legendType: "pie",
        colors: this.colorArr,
      });

      //开始画图
      let width = this.$refs.refDonutChartHolder.clientWidth;
      this.$refs.refDonutChart.DonutChart(data.data, {
        name: (d) => d.label,
        value: (d) => d.value,
        width: width,
        height: width * this.widthRatio * 0.85,
        donutRatio: 0.4, //donut的内圆半径占比
        padAngle: 2, //pad间隔角度
        cornerRadius: 5, //圆角
        colors: this.colorArr,
        duration: 600,
        delay: 100,
        ease: "easeCubicInOut",
        labelPosition: "inside",
        valueSuffix: "%",
        isRotateLabel: false,
      });
    },
    renderKLineChart(data) {
      //开始画图
      let width = this.$refs.refKLineChartHolder.clientWidth;
      this.$refs.refKLineChart.KLineChart(data.data, {
        label: (d) => d.label,
        value: (d) => d.value,
        width: width,
        height: width * this.widthRatio * 0.95,
        marginTop: 20,
        marginBottom: 40,
        marginLeft: 100,
        marginRight: 80,
        duration: 200,
        delay: 12,
        ease: "easeCubicInOut",
        curveName: "curveLinear",
        xTickNumber: 3,
        yTickNumber: 6,
      });
    },
    renderBarDoubleChart(data) {
      //展示legend
      this.$refs.refBarDoubleLegend.createLegend({
        legend: data.legend,
        legendType: "bar",
        colors: this.colorArr,
      });
      //开始画图
      let width = this.$refs.refBarDoubleChartHolder.clientWidth;
      this.$refs.refBarDoubleChart.BarChart(data.data, {
        x: (d) => d.label,
        width: width,
        height: width * this.widthRatio * 0.95,
        marginLeft: 110,
        marginRight: 80,
        marginBottom: 60,
        zPadding: 0.05,
        xPadding: 0.3,
        duration: 1200,
        delay: 120,
        ease: "easeCubic",
        xTickNumber: 3,
        yTickNumber: 10,
        colors: this.colorArr,
        isShowText: true,
      });
    },
    renderBarStackedChart(data) {
      //展示legend
      this.$refs.refBarStackedLegend.createLegend({
        legend: data.legend,
        legendType: "bar",
        colors: this.colorArr,
      });
      //开始画图
      let width = this.$refs.refBarStackedChartHolder.clientWidth;
      this.$refs.refBarStackedChart.StackedBarChart(data.data, {
        y: (d) => d.label,
        width: width,
        height: width * this.widthRatio * 0.95,
        marginLeft: "auto",
        marginRight: width * 0.22,
        marginTop: 0,
        marginBottom: 0,
        yPadding: 0.5,
        duration: 480,
        delay: 120,
        numberSuffix: data.suffix,
        popoverVisible: false,
        ease: "easeCubic",
        colors: this.colorArr,
      });
    },
    renderRoseChart(data) {
      //展示legend
      this.$refs.refRoseChartLegend.createLegend({
        legend: data.legend,
        legendType: "pie",
        colors: this.colorArr,
      });
      //开始画图
      let width = this.$refs.refRoseChartHolder.clientWidth;
      this.$refs.refRoseChart.DonutChart(data.data, {
        width: width,
        height: width * this.widthRatio * 0.95,
        name: (d) => d.label,
        value: (d) => d.value,
        width: width,
        height: width * 0.7,
        donutRatioRange: [0.13, 0.4, 0.7], //空心内圈的半径占比，最小半径占比和最大半径占比
        duration: 240,
        delay: 40,
        labelOffset: 80,
        isRotateLabel: false,
        ease: "easeBackOut",
        valueType: "relative",
        valueSuffix: data.suffix,
        isSort: true,
        isSameAngle: false,
        colors: this.colorArr,
      });
    },
    renderBarRangeChart(data) {
      //开始画图
      let width = this.$refs.refBarRangeChartHolder.clientWidth;
      this.$refs.refBarRangeChart.BarRangeChart(data.data, {
        label: (d) => d.label,
        valueLow: (d) => d.value2,
        valueHigh: (d) => d.value1,
        width: width,
        height: width * this.widthRatio,
        minLabelWidth: 100, //左边标签留出来的空间，否则可能会与label显示重叠了
        marginLeft: "auto",
        marginRight: 100,
        marginBottom: 40,
        duration: 300,
        delay: 100,
        yPadding: 0.8,
        ease: "easeBack",
        xTickNumber: 6,
      });
    },
    renderTableChart(data) {
      //开始画表格
      let width = this.$refs.refTableChartHolder.clientWidth;
      this.$refs.refTableChart.TableChart(data.data, {
        width: width,
        height: width * this.widthRatio,
        duration: 500,
        delay: 100,
        ease: "easeOutCubic",
      });
    },
    renderRectTreeChart(data) {
      let width = this.$refs.refTreeMapHolder.clientWidth;
      this.$refs.refTreeMap.TreeMap(data.data, {
        value: (d) => d.value1,
        width: width,
        height: width * this.widthRatio,
        valueSuffix: data.suffix ? data.suffix : "",
        padding: 8,
        rectRadius: 4,
        duration: 400,
        delay: 20,
        colors: this.colorArr,
        ease: "easeCircleInOut",
        valueMap: [10, 40],
        fontSizeMap: [28, 40],
      });
    },
  },

  async mounted() {
    window.show = this.preview;
    window.hide = this.hide;

    this.$bus.$on("chartExit", () => {
      this.hide();
    });
  },
};
</script>
<style lang="less" scoped>
@import "index.less";
</style>
