<template>
  <div :id="id" :class="customClass"></div>
</template>

<script>
import * as d3 from "d3";
import $ from "jquery";
import { sleep, randomNum } from "@/utils/utils.js";
export default {
  props: ["id", "customClass"],
  data() {
    return {
      svg: null,
    };
  },
  methods: {
    async playOut() {
      this.svg
        .selectAll(`.kbar`)
        .transition()
        .delay((d, i) => i * 40)
        .duration(300)
        .ease(d3.easeCubicIn)
        .attr("height", 0)
        .style("opacity", "0");
      this.svg
        .selectAll(`.kline`)
        .transition()
        .delay((d, i) => i * 40)
        .duration(300)
        .ease(d3.easeCubicIn)
        .style("opacity", "0");
      this.svg
        .selectAll(`.axis_x .domain,.axis_y .domain`)
        .transition()
        .duration(300)
        .style("opacity", "0");
      this.svg
        .selectAll(`.axis_x .tick,.axis_y .tick`)
        .transition()
        .duration(300)
        .delay((d, i) => i * 40)
        .ease(d3.easeCubicIn)
        .style("opacity", "0");
    },
    async init() {
      await this.getSettings();
    },
    getSettings() {
      return new Promise((resolve, reject) => {
        this.$nextTick(() => {
          resolve();
        });
      });
    },
    //核心库
    async KLineChart(
      data,
      {
        label = (d, i) => d, // given d in data, returns the (ordinal) x-value
        value = (d, i) => d, // given d in data, returns the value array
        curveName = "curveLinear",
        marginTop = 40, // the top margin, in pixels
        marginRight = 50, // the right margin, in pixels
        marginBottom = 30, // the bottom margin, in pixels
        marginLeft = 40, // the left margin, in pixels
        width = 640, // the outer width of the chart, in pixels
        height = 400, // the outer height of the chart, in pixels
        xPadding = 0.5,
        xDomain, // an array of (ordinal) x-values
        xRange = [marginLeft, width - marginRight], // [left, right]
        yDomain, // [ymin, ymax]
        yRange = [height - marginBottom, marginTop], // [bottom, top]
        yFormat = ",f", // a format specifier string for the y-axis
        duration = 400, //动画持续时长
        delay = 40, //元素之间间隔时长
        ease = "easeQuadOut", //元素之间间隔时长
        isFromZero = true, //默认最小值从0开始
        xTickNumber = 5,
        yTickNumber = 5,
      } = {}
    ) {
      const curve = d3[curveName];
      const X = d3.map(data, label);
      const Y = d3.map(data, value);
      console.log("X", X);
      console.log("Y", Y);

      xDomain = X;

      const yMin = d3.min(Y, function (d) {
        return d[2];
      });
      const yMax = d3.max(Y, function (d) {
        return d[3];
      });

      yDomain = [yMin - [yMax - yMin] * 0.06, yMax];

      const xScale = d3.scaleBand(xDomain, xRange).padding(xPadding);
      const yScale = d3.scaleLinear(yDomain, yRange);

      //算出每几个标一个tick
      let tickValuesArr = [];
      if (xTickNumber == 2) {
        tickValuesArr.push(xDomain[0]);
        tickValuesArr.push(xDomain[xDomain.length - 1]);
      } else if (xTickNumber == 3) {
        tickValuesArr.push(xDomain[0]);
        tickValuesArr.push(xDomain[Math.floor(xDomain.length / 2)]);
        tickValuesArr.push(xDomain[xDomain.length - 1]);
      } else if (xTickNumber > 3) {
        const modeNum = Math.floor(data.length / xTickNumber);
        tickValuesArr = xScale.domain().filter((d, i) => i % modeNum == 0);
      }

      const xAxis = d3.axisBottom(xScale).tickSize(10).tickSizeOuter(0).tickValues(tickValuesArr);
      const yAxis = d3.axisLeft(yScale).ticks(yTickNumber, yFormat).tickSize(10).tickSizeOuter(0);

      const svg = d3
        .create("svg")
        .attr("width", width)
        .attr("height", height)
        .attr("viewBox", [0, 0, width, height])
        .attr("style", "max-width: 100%; height: auto; height: intrinsic;");
      this.svg = svg;

      //y轴坐标轴
      const axisY = svg
        .append("g")
        .attr("class", "axis_y")
        .attr("transform", `translate(${marginLeft},0)`)
        .call(yAxis)
        .call((g) => {
          g.select(".domain").attr("opacity", 0);
          g.selectAll(".tick line").attr("class", "tick_line").attr("opacity", 0);
          g.selectAll(".tick line")
            .clone()
            .attr("x2", width - marginLeft - marginRight)
            .attr("stroke", "rgba(0,0,0,.1)")
            .attr("class", "tick_long_line");
          g.selectAll(".tick text").attr("class", "text").attr("opacity", 0);
        });
      axisY.selectAll(".domain").transition().duration(600).attr("opacity", 1);
      axisY.selectAll(".tick_line").transition().duration(600).attr("opacity", 1);
      axisY.selectAll(".tick_long_line").transition().duration(600).attr("opacity", 1);
      axisY
        .selectAll(".text")
        .transition()
        .delay((d, i) => i * 60)
        .ease(d3.easeCircleOut)
        .duration(300)
        .attr("opacity", 1);

      //x轴坐标轴
      const axisX = svg
        .append("g")
        .attr("class", "axis_x")
        .attr("transform", `translate(0,${height - marginBottom + 1})`)
        .call(xAxis)
        .call((g) => {
          g.select(".domain").attr("opacity", 0);
          g.selectAll(".tick line").attr("class", "tick_line").attr("opacity", 0);
          g.selectAll(".tick text").attr("class", "text").attr("opacity", 0);
        });

      axisX.selectAll(".domain").transition().duration(300).attr("opacity", 1);
      axisX.selectAll(".tick_line").transition().duration(300).attr("opacity", 1);
      axisX
        .selectAll(".text")
        .transition()
        .delay((d, i) => i * 60)
        .ease(d3.easeCircleOut)
        .duration(300)
        .attr("opacity", 1);

      var lines = svg
        .append("g")
        .attr("class", "k_lines_group")
        .selectAll("line")
        .data(X)
        .enter()
        .append("line")
        .attr("x1", (d, i) => xScale(d) + xScale.bandwidth() / 2) //open close low high
        .attr("x2", (d, i) => xScale(d) + xScale.bandwidth() / 2)
        .attr("y1", (d, i) => yScale(Y[i][0] / 2 + Y[i][1] / 2))
        .attr("y2", (d, i) => yScale(Y[i][0] / 2 + Y[i][1] / 2)) //线从开盘收盘的中点向上下两端生长
        .attr("class", (d, i) =>
          Y[i][0] <= Y[i][1] ? "kline kline_positive" : "kline kline_negative"
        )
        .attr("stroke", "currentColor");

      lines
        .transition()
        .delay((d, i) => i * delay)
        .duration(duration)
        .attr("y1", (d, i) => yScale(Y[i][2]))
        .attr("y2", (d, i) => yScale(Y[i][3]));

      //k线的柱子
      var rects = svg
        .append("g")
        .attr("class", "k_bars_group")
        .selectAll("rect")
        .data(X)
        .enter()
        .append("rect")
        .attr("x", (d, i) => xScale(d))
        .attr("y", (d, i) => yScale(Y[i][0]))
        // return d.fOpen < d.fClose ? yScale(d3.min([d.fClose, d.fHigh])) : yScale(d3.max([d.fClose, d.fLow]));
        .attr("class", (d, i) => (Y[i][0] <= Y[i][1] ? "kbar kbar_positive" : "kbar kbar_negative"))
        .attr("width", xScale.bandwidth())
        .attr("height", 0);

      rects
        .transition()
        .delay((d, i) => i * delay)
        .duration(duration)
        .attr("y", (d, i) => yScale(d3.max([Y[i][0], Y[i][1]])))
        .attr("height", (d, i) => {
          const rectHeight = Math.abs(yScale(Y[i][0]) - yScale(Y[i][1]));
          return rectHeight == 0 ? 1 : rectHeight;
        });

      $("#" + this.id).html(svg.node());
    },
  },
  mounted() {
    this.init();
  },
};
</script>
<style lang="less" scoped></style>
