<template>
  <div :id="id" :class="customClass"></div>
</template>

<script>
import * as d3 from "d3";
import $ from "jquery";
export default {
  props: ["id", "customClass"],
  data() {
    return {};
  },
  methods: {
    async playOut() {
      this.svg
        .selectAll(`.bar`)
        .transition()
        .delay((d, i) => i * 40)
        .duration(180)
        .attr("width", "0")
        .attr("x", "0")
        .ease(d3.easeCubicIn);
      this.svg
        .selectAll(`.tick`)
        .transition()
        .delay((d, i) => i * 40)
        .duration(180)
        .style("opacity", "0")
        .ease(d3.easeCubicIn);
      this.svg
        .selectAll(`.numbers`)
        .transition()
        .delay((d, i) => i * 40)
        .duration(180)
        .style("opacity", "0")
        .ease(d3.easeCubicIn);
    },
    sum(obj, keys = []) {
      let num = 0;
      keys.forEach((i) => {
        num += obj[i];
      });
      return num;
    },
    xSum(fn, obj, keys) {
      let num = 0;
      keys.forEach((i) => {
        num += fn(obj[i]);
      });
      return num;
    },
    //核心库
    StackedBarChart(
      data,
      {
        y = (d) => d, //横向的是数值
        marginTop = 0, // the top margin, in pixels
        marginRight = 60, // the right margin, in pixels
        marginBottom = 0, // the bottom margin, in pixels
        marginLeft = 60, // the left margin, in pixels
        width = 440, // the outer width of the chart, in pixels
        height = 350, // the outer height of the chart, in pixels
        yPadding = 0.6, // 柱子的比例
        duration = 1000, //动画持续时长
        delay = 400, //元素之间间隔时长
        ease = "easeCubicOut", //元素之间间隔时长
        numberSuffix = "", // 堆叠总数后缀
        numberVisible = true, // 是否显示堆叠总数
        popoverWidth = 126, // 气泡宽度
        popoverHeight = 32, // 气泡高度
        isPopOverVisible = false, // 是否绘制气泡
        colors = ["#ff3600", "#f76f00", "#e19a00", "#c0c000", "#8ee100", "#01ff37"],
        keys = [],
      } = {}
    ) {
      const Y = d3.map(data, y);

      //marginLeft为auto时，自动根据最长的label设定marginLeft，拥有最强的兼容性
      if (marginLeft == "auto") {
        //找到文字最长的那个label
        let maxLengthLabel = "";
        Y.map((item) => {
          if (item.length > maxLengthLabel) {
            maxLengthLabel = item;
          }
        });
        const svgForLabel = d3
          .create("svg")
          .attr("style", "position:absolute; left:0px; top:0px")
          .attr("width", 300)
          .attr("height", 300)
          .attr("viewBox", [0, 0, 300, 300]);
        svgForLabel
          .append("text")
          .attr("x", 90)
          .attr("y", 90)
          .attr("class", "max_label_invisible axis_y")
          .attr("text-anchor", "middle")
          .text(maxLengthLabel);
        $("#" + this.id).html(svgForLabel.node());

        const maxLabel = document
          .querySelector(`#${this.id} .max_label_invisible`)
          .getBoundingClientRect();
        marginLeft = maxLabel.width + 30;
      }

      // 创建svg
      const svg = d3
        .create("svg")
        .attr("width", width)
        .attr("height", height)
        .attr("viewBox", [0, 0, width, height])
        .attr("style", "max-width: 100%; height: auto; height: intrinsic;");
      this.svg = svg;

      //数出有几组value
      for (const key in data[0]) {
        if (key.indexOf("value") == 0) {
          keys.push(key);
        }
      }

      // 绘制区域
      const innerWidth = width - marginRight - marginLeft;
      const innerHeight = height - marginTop - marginBottom;
      // 定义比例尺
      const xScale = d3
        .scaleLinear()
        .domain([0, d3.max(data.map((d) => this.sum(d, keys)))])
        .range([0, innerWidth]);
      const yScale = d3.scaleBand().domain(Y).range([0, innerHeight]).padding(yPadding);
      // 定义堆叠器
      const stack = d3.stack().keys(keys).offset(d3.stackOffsetNone);
      // const stack = d3.stack().keys(keys).order(d3.stackOrderAscending).offset(d3.stackOffsetNone);   //order可以让数据排序
      // y轴
      const yAxis = d3.axisLeft(yScale).tickSizeOuter(0);
      $("#" + this.id).html(svg.node());
      // 创建图层
      const g = svg.append("g").attr("transform", `translate(${marginLeft}, ${marginTop})`);
      // 绘制y轴
      g.append("g")
        .attr("class", "axis_y")
        .call(yAxis)
        .call((g) => {
          g.select(".domain").remove();
          g.selectAll(".tick line").remove();
          g.selectAll(".tick text").attr("class", "text").attr("opacity", 0);
        })
        .selectAll(".text")
        .transition()
        .delay((d, i) => i * delay)
        .duration(duration)
        .ease(d3[ease])
        .attr("opacity", 1);
      // 绘制堆叠柱图
      let barGroup = g.selectAll(".barGroup").data(stack(data));
      let bars = barGroup
        .enter()
        .append("g")
        .merge(barGroup)
        .attr("class", "barGroup")
        .attr("fill", (d, i) => colors[i])
        .selectAll(".bar")
        .data((d) => {
          return d.map((item) => {
            item.index = d.index;
            item.name = d.key;
            return item;
          });
        });
      bars
        .enter()
        .append("rect")
        .attr("class", "bar")
        .merge(bars)
        .attr("y", (d, i) => yScale(Y[i]))
        .attr("height", yScale.bandwidth())
        .attr("width", 0)
        .transition()
        .delay((d, i) => i * delay)
        .duration(duration)
        .attr("width", (d) => xScale(d[1]) - xScale(d[0]))
        .attr("x", (d) => xScale(d[0]));
      // 绘制堆叠柱状图总数
      if (numberVisible) {
        g.selectAll(".numbers")
          .data(data)
          .enter()
          .append("text")
          .attr("class", "numbers")
          .attr("fill", "currentColor")
          .text((d) => `${this.sum(d, keys)}${numberSuffix}`)
          .attr("y", (d, i) => yScale(Y[i]) + yScale.bandwidth() / 2)
          .attr("dy", "0.35em")
          .attr("dx", (i) => this.xSum(xScale, i, keys) + 12)
          .style("opacity", "0")
          .transition()
          .delay((d, i) => (i + 1) * delay)
          .ease(d3[ease])
          .duration(duration)
          .style("opacity", "1");
      }
      // 绘制气泡
      if (isPopOverVisible && keys.length === 2) {
        g.selectAll(".popover")
          .data(data)
          .enter()
          .append("foreignObject")
          .attr("class", "svg-popover")
          .attr("width", popoverWidth)
          .attr("height", popoverHeight + 6)
          .attr("x", (d, i) => xScale(d[keys[0]]))
          .attr("y", (d, i) => yScale(Y[i]) - popoverHeight - 12)
          .style("transform", `translate(-${popoverWidth / 2}px, 0)`)
          .append("xhtml:div")
          .attr("class", "popover")
          .html((d) => d.des)
          .style("width", "100%")
          .style("height", popoverHeight)
          .style("position", "relative")
          .style("background", colors[0])
          .style("color", "#fff")
          .style("border-radius", "16px")
          .style("line-height", `${popoverHeight}px`)
          .style("text-align", "center")
          .append("span")
          .style("position", "absolute")
          .style("border", "6px solid transparent")
          .style("border-top", `6px solid ${colors[0]}`)
          .style("top", "100%")
          .style("left", "50%")
          .style("transform", "translateX(-50%)");
        // 气泡动画
        d3.selectAll(".svg-popover")
          .style("opacity", "0")
          .style("transform", `translate(-${popoverWidth / 2}px, 30px)`)
          .transition()
          .delay((d, i) => (i + 1) * delay)
          .ease(d3[ease])
          .duration(duration)
          .style("opacity", "1")
          .style("transform", `translate(-${popoverWidth / 2}px, 0)`);
      }
    },
  },
};
</script>
